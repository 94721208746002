.footer {
  @apply bg-white;

  &__top {
    @apply flex justify-between flex-wrap flex-col;
    @apply lg:flex-nowrap lg:pt-5 lg:pb-5 lg:flex-row;
  }

  &__logo {
    @apply mb-6 w-60 p-3;
    @apply lg:mb-0 lg:mx-0 lg:p-0;

    img {
      @apply max-w-52;
    }
  }

  &__links {
    @apply mb-3 lg:mb-0;

    &-item {
      @apply mb-2;
      @apply lg:mx-10 lg:mb-0;

      a {
        @apply text-gray-600 text-base transition ease-in duration-300;
        @apply hover:text-gray-900;
      }
    }

    .title {
      @apply mb-3 font-medium;
    }
  }

  &__contact {
    @apply mb-4 text-gray-600;
    @apply lg:mb-0 lg:text-sm;

    &-address {
      @apply max-w-[300px] md:ml-auto;

      p {
        @apply mb-4 text-gray-600;
      }
    }

    &-social {
      @apply max-w-[300px] md:ml-auto;

      h3 {
        @apply font-semibold mb-4;
      }

      i {
        @apply text-4xl text-gray-600 transition ease-in duration-300;
        @apply hover:text-gray-900;
      }
    }
  }


  &__logo,
  &__links,
  &__contact {
    @apply flex-1;
  }

  &__bottom {
    p {
      @apply text-center py-2 text-gray-600 text-xs;
    }
  }

  .whatsapp-contact {
    @apply fixed right-0 bottom-4 w-16 z-10 drop-shadow-md;
    @apply lg:right-6;
  }

  .back-to-top {
    @apply fixed bottom-32 z-10 drop-shadow-lg opacity-0 -right-3 transition-all duration-300 ease-in cursor-pointer;

    &.-active {
      @apply opacity-50 right-3;
      @apply lg:right-9;
      @apply hover:opacity-100;
    }

    i {
      @apply text-3xl text-white transition ease-in duration-300 bg-theme-light-blue p-1 rounded;
    }
  }
}
