// @import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600&display=swap');

* {
  font-family: "Quicksand", Arial, Helvetica, sans-serif;
  @apply text-black;
}

.wrapper {
  @apply container px-4;
}

.hero-slider {
  @apply relative;

  &__arrows {
    .splide__arrow {
      @apply bg-theme-light-blue hover:bg-theme-dark-blue transition-colors duration-200 w-10 h-10;

      svg {
        @apply fill-white w-4 h-4;
      }
    }
  }

  .splide__pagination {
    @apply -bottom-6;

    .splide__pagination__page {
      @apply w-6 h-2 rounded-none bg-gray-300 rounded-md;

      &.is-active {
        @apply bg-theme-light-blue transform-none w-10;
      }
    }
  }
}

.categories {
  @apply bg-white py-10;

  h2 {
    @apply text-2xl md:text-4xl text-center font-bold tracking-wider mb-5 md:mb-10 text-theme-light-blue;
  }

  &-wrapper {
    @apply inline-flex flex-wrap gap-y-5;
  }

  .category-item {
    @apply relative shadow-md rounded-xl m-2;
    width: calc(50% - 1rem);

    @screen md {
      width: calc(33.333% - 1rem);
    }

    @screen lg {
      width: calc(25% - 1rem);
    }

    &__img {
      @apply w-full object-cover rounded-xl overflow-hidden transition-all duration-200;
      height: 300px;
      @apply hover:scale-[1.02];

      &:hover {
        @apply shadow-3px shadow-theme-light-blue;
      }
    }

    &__title {
      @apply absolute bottom-0 bg-theme-light-blue py-2 px-4 bg-opacity-90 text-xl font-bold text-white w-full text-center rounded-b-xl;
    }

    // &:hover {
    //   .category-item__title {
    //     @apply -bottom-1 scale-[1.02];
    //   }
    // }
  }
}

.references {
  @apply bg-gray-50 py-10;

  h2 {
    @apply text-2xl md:text-4xl text-center font-bold tracking-wider mb-5 md:mb-10 text-theme-light-blue;
  }

  &-slider {
    @apply py-5 relative;

    &__img {
      @apply mx-auto rounded-lg shadow-md transition-all duration-200;
      @apply hover:shadow-lg hover:scale-105;
    }

    &__arrows {
      .splide__arrow {
        @apply absolute top-1/2 -translate-y-1/2 bg-theme-light-blue hover:bg-theme-dark-blue transition-colors duration-200 w-10 h-10;

        svg {
          @apply fill-white w-4 h-4;
        }

        &--prev {
          @apply left-0 md:-left-12;
        }

        &--next {
          @apply right-0 md:-right-12;
        }
      }
    }

    .splide__pagination {
      @apply -bottom-4;

      .splide__pagination__page {
        @apply w-6 h-2 rounded-none bg-gray-300 rounded-md;

        &.is-active {
          @apply bg-theme-light-blue transform-none w-10;
        }
      }
    }

    .splide__track {
      @apply py-4;
    }
  }
}

.our-working-zones {
  @apply py-10;

  .main-title {
    @apply text-5xl mb-10 text-center;
  }

  .main-desc {
    @apply mb-5 text-center;
  }

  &__items {
    @apply flex flex-wrap;
  }

  &__item {
    @apply flex flex-col m-4 p-8 border-4 border-theme-dark-blue transition-colors duration-200 ease-in;
    @apply hover:border-theme-dark-blue hover:bg-theme-dark-blue hover:shadow-xl hover:shadow-slate-300;

    @screen md {
      width: calc(50% - 32px);
    }

    @screen lg {
      width: calc(33.3% - 32px);
    }

    &:hover {
      .icon i,
      .title h3,
      .description {
        @apply text-theme-dark-blue;
      }
    }

    .icon {
      @apply text-center text-6xl;
    }

    .title {
      h3 {
        @apply text-center text-2xl mb-5 text-theme-dark-blue;
      }
    }

    .description {
      @apply text-center text-theme-dark-blue;
    }
  }
}

.teams {
  @apply bg-[url('/images/teams-bg.jpg')] py-20 bg-no-repeat bg-center bg-cover;

  &-title {
    h2 {
      @apply text-center text-5xl mb-10 text-white;
    }
  }

  &-item {
    @apply flex flex-col;
    @apply lg:flex-row;
  }

  &-left {
    @apply mb-5;
    @apply lg:w-1/3;
  }

  &-right {
    @apply flex flex-col justify-between;
    @apply lg:w-2/3 lg:pl-16 lg:pr-10;
  }

  &-name {
    h2 {
      @apply text-2xl mb-5 text-slate-300 font-normal;
    }
  }

  &-cv {
    p {
      @apply text-slate-300 font-light leading-9 mb-3;
    }
  }

  .splide__pagination__page {
    @apply opacity-50;

    &.is-active {
      @apply bg-theme-dark-blue opacity-100;
    }
  }

  .splide__arrow {
    @apply shadow-lg;
  }

  .splide__pagination {
    @apply -bottom-8;
  }
}

.articles {
  &-title {
    @apply text-center text-5xl my-10 text-theme-dark-blue;
  }

  &-wrapper {
    @apply bg-[#F8F8F8] px-5 pt-10 pb-5 flex flex-wrap justify-around;
  }

  &-item {
    @apply w-[47%] transition-all;
    @apply hover:shadow-xl hover:shadow-gray-300;

    &__link {
      @apply flex flex-col p-5;
      @apply lg:flex-row;
    }

    &-left {
      @apply mb-5;
      @apply lg:mb-0 lg:w-1/3;

      img {
        @apply object-cover w-full h-[205px];
      }
    }

    &-right {
      @apply flex flex-col;
      @apply lg:pl-5 lg:w-2/3;
    }

    &__title {
      h2 {
        @apply text-xl font-medium mb-3 font-oswald;
      }
    }

    &__desc {
      @apply text-sm font-light mb-3 tracking-tighter;
    }

    &__date {
      @apply text-xs font-extralight text-gray-500 font-oswald;
    }
  }

  &-button {
    @apply flex justify-center my-5;
  }
}

.border-button {
  a,
  button {
    @apply border-4 px-10 py-3 text-sm font-light transition-all duration-300 inline-block;
  }

  &.-white {
    a,
    button {
      @apply border-stone-200 text-slate-200;
      @apply hover:bg-stone-200 hover:text-theme-dark-blue hover:shadow-sm hover:shadow-slate-600;
    }
  }

  &.-blue {
    a,
    button {
      @apply border-theme-dark-blue text-theme-dark-blue;
      @apply hover:bg-theme-dark-blue hover:text-stone-200 hover:shadow-sm hover:shadow-slate-600;
    }
  }
}
