.contact {
  @apply lg:flex;

  &-left {
    @apply w-full p-6;
    @apply lg:w-1/2;

    iframe {
      @apply rounded-md shadow-xl mt-8;
    }
  }

  &-right {
    @apply w-full p-6 mt-5;
    @apply lg:w-1/2 lg:mt-0;

    &__info {
      @apply mb-8 font-medium text-theme-dark-blue;
    }
  }

  &__form {
    &-label {
      @apply block text-sm text-gray-700 mb-2;
    }

    &-input {
      @apply border border-gray-200 block w-full h-9 rounded-md text-gray-700 text-base p-2;
      @apply lg:w-60;

      &.-desc {
        @apply h-auto w-full;
      }
    }

    &-row {
      @apply mb-6;
    }

    &-submit {
      @apply btn-primary mb-4 py-2 px-6;
    }
  }
}

.alert {
  @apply mb-4;

  &-danger {
    @apply bg-red-50 block border border-red-200 p-2;

    li {
      @apply text-red-500;
    }
  }

  &-success {
    @apply text-green-800 p-4 bg-green-50;
  }

  li {
    @apply leading-6;
  }

  .close {
    @apply hidden;
  }
}
