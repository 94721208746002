@layer components {
  .container {
    @apply mx-auto;
  }

  .btn-primary {
    @apply inline-flex cursor-pointer rounded-lg bg-theme-dark-blue text-sm font-normal tracking-wider text-white shadow-md transition-all duration-200 ease-in-out;
    @apply hover:bg-theme-light-blue hover:shadow-lg focus:outline-none focus:ring-4 focus:ring-theme-light-blue focus:ring-opacity-50;

    i {
      @apply mr-1 text-xl leading-5 text-white;
    }
  }

  .btn-secondary {
    @apply inline-flex cursor-pointer rounded-lg border border-theme-dark-blue text-sm font-normal tracking-wider text-theme-dark-blue shadow-md transition-all duration-200 ease-in;
    @apply hover:bg-theme-light-blue hover:text-white hover:shadow-lg focus:outline-none focus:ring-4 focus:ring-theme-light-blue focus:ring-opacity-50;

    i {
      @apply mr-1 text-xl leading-5 text-theme-dark-blue;
    }

    &:hover {
      i {
        @apply text-white;
      }
    }
  }
}
