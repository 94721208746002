.categori-detail {
  @apply relative flex flex-col items-start;
  @apply lg:flex-row;

  &__side-menu {
    @apply invisible h-0 w-full -translate-x-96 bg-white opacity-0 transition-transform duration-300 ease-in-out;
    @apply translate-x-0 lg:visible lg:mb-8 lg:mr-8 lg:block lg:h-auto lg:w-1/5 lg:bg-white lg:py-4 lg:opacity-100 lg:shadow;

    &.-active {
      @apply visible top-16 z-10 h-auto -translate-x-0 opacity-100;
    }

    &-button {
      @apply z-10 mb-4 flex w-full items-center justify-between bg-white p-4 shadow;
      @apply lg:z-0 lg:hidden;

      i {
        @apply text-3xl text-gray-600;
      }
    }

    h2 {
      @apply border-b border-gray-200 px-6 py-4 text-xl font-medium;
      @apply lg:pt-0;
    }

    li {
      @apply px-4 py-2 font-light;
    }

    .categori-link {
      @apply flex cursor-pointer items-center justify-between;

      img {
        @apply mr-3 w-5;
      }

      span {
        @apply flex-1;
      }

      i {
        @apply text-gray-600;
      }
    }

    .categori-menu {
      &.-active {
        @apply bg-theme-dark-blue;

        .categori-link {
          @apply mb-2 font-bold text-neutral-300;
        }

        .categori-sub-menu {
          max-height: 400px;
        }

        span {
          @apply text-neutral-100;
        }

        i {
          @apply rotate-90 text-neutral-300;
        }

        img {
          @apply brightness-0 invert;
        }
      }
    }

    .categori-sub-menu {
      @apply max-h-0 overflow-hidden transition-max-height duration-300 ease-in-out;

      li {
        @apply pl-8;
      }

      li:has(a.-active) {
        @apply bg-slate-100 text-theme-light-blue;
      }
    }

    .categori-link__child {
      @apply text-neutral-300 transition-all duration-300 ease-in-out;
      @apply hover:text-white hover:drop-shadow-lg;

      &.-active {
        @apply text-theme-light-blue;
      }
    }
  }

  &__list {
    @apply w-full;
    @apply lg:w-4/5;

    &-header {
      @apply flex items-center justify-between bg-white p-4 shadow;

      .order-tab {
        @apply border-gray-200 text-sm text-gray-500 transition-colors duration-300 ease-in-out;
        @apply hover:text-gray-800;
      }
    }

    &-main {
      @apply my-4 grid grid-cols-1 gap-x-4 gap-y-4;
      @apply sm:grid-cols-2;
      @apply lg:grid-cols-4;
      @apply xl:gap-x-4;
    }
  }

  .categori-paginate {
    @apply my-8;
  }
}

.product-cart {
  @apply border border-gray-100 bg-white px-3 py-4 shadow-md transition-all;
  @apply hover:shadow-lg;

  &__img {
    @apply mb-5;
  }

  &__info {
    @apply flex flex-col;
  }

  .name {
    @apply my-1 font-light text-gray-700;
  }

  .price {
    @apply font-oswald font-thin text-gray-500;
  }
}

.overlay {
  &.-show {
    @apply fixed bottom-0 left-0 right-0 top-0 h-full w-screen bg-black opacity-50 transition-opacity duration-300 ease-in-out;
  }
}
