.product {
  @apply mb-10 flex flex-wrap;

  &__img {
    @apply w-full bg-white;
    @apply lg:w-1/2;

    .big-image {
      @apply mb-4 w-[600px];
    }

    .thumbnail-image {
      @apply p-1;
    }
  }

  &__info {
    @apply my-4 w-full px-8;
    @apply lg:my-0 lg:w-1/2;

    .name {
      @apply mb-4 px-4 text-xl font-medium;
    }

    .product-features-1 {
      @apply my-4 w-full border-separate text-sm;
      @apply xl:w-3/4;
      @apply 2xl:w-2/3;

      tr {
        @apply even:bg-gray-100;
        @apply odd:bg-gray-200;
      }

      td {
        @apply mb-[2px] rounded-md py-2 px-4;
        @apply first:font-medium;
      }

      .download-btn {
        @apply rounded-md bg-theme-dark-blue px-4 py-1 text-sm font-bold text-white transition-all duration-300 ease-in-out;
        @apply hover:bg-theme-light-blue;

        i {
          @apply mr-1 text-white;
        }
      }
    }

    .product-features-2 {
      @apply mb-4 w-full rounded-md bg-white p-4 leading-8 shadow-sm max-h-96 overflow-auto;
      @apply xl:w-3/4;
      @apply 2xl:w-2/3;

      h2 {
        @apply mb-2 rounded-md bg-theme-light-blue p-1 text-center text-base font-medium text-white;
      }

      ul {
        @apply list-outside list-disc px-4 text-sm;
      }

      li {
        @apply mb-2;
      }

      b {
        @apply font-semibold underline;
      }
    }

    .btn {
      @apply btn-secondary mb-4 py-1 px-4;
      @apply hover:drop-shadow-md;
    }
  }

  .main-slider {
    @apply mx-auto max-w-[600px] p-8;
  }

  .thumbnail-slider {
    @apply mx-auto mb-10 max-w-[600px];

    .splide__list {
      @apply justify-center;
    }

    .splide__list > .splide__slide.is-active {
      @apply rounded-md border-theme-dark-blue;
    }
  }

  .modal-share {
    &__wrapper {
      @apply -m-3 flex flex-wrap;
    }

    &__item {
      @apply m-3 w-1/3 p-3 text-center text-white;
      @apply hover:shadow-xl focus:outline-none focus:ring-4 focus:ring-gray-400 focus:ring-opacity-50;
      flex: 1 0 0;

      &.icon-whatsapp {
        @apply bg-[#25d366];
      }

      &.icon-facebook {
        @apply bg-[#3b5998];
      }

      &.icon-twitter {
        @apply bg-[#1da1f2];
      }

      &.icon-linkedin {
        @apply bg-[#0a66c2];
      }

      &.icon-pinterest {
        @apply bg-[#bd081c];
      }

      &.icon-mail {
        @apply bg-[#a6b1b7];
      }

      i {
        @apply mx-auto mb-2 block w-16 text-5xl text-white;
      }
    }

    &__link {
      @apply m-3;
    }
  }

  .recommendation-slider {
    @apply max-w-full;
  }
}
