.header {
  @apply border-b border-gray-200;

  &__top-bar {
    @apply bg-theme-dark-blue;

    .header__nav-menu-link {
      @apply text-lg text-gray-200 transition-colors duration-300;
      @apply hover:text-white;
    }
  }

  &__address {
    @apply hidden;
    @apply lg:flex;

    i {
      @apply mr-1;
    }
  }

  &__mail,
  &__phone {
    @apply items-center hidden;
    @apply lg:flex;

    i {
      @apply mr-2 text-theme-light-blue text-3xl;
    }
  }

  &__logo {
    @apply w-20;
    @apply lg:w-44;
  }

  &__bottom {
    @apply bg-white py-1;

    .wrapper {
      @apply flex items-center;
    }
  }

  &__search {
    @apply w-auto relative flex items-center;
    @apply lg:w-64;

    input {
      @apply border border-gray-500 text-sm placeholder:text-gray-300 pr-10 w-44 text-gray-600 rounded-full;
      @apply focus:ring-0 focus:border-theme-dark-blue placeholder:text-xs;
      @apply lg:w-full lg:flex-1 border border-gray-200 lg:py-2 lg:pl-6 lg:text-sm lg:outline-none lg:transition-all lg:duration-300 lg:ease-in lg:outline-0 lg:placeholder:text-sm;
    }

    button {
      @apply absolute right-0 lg:right-10 py-2 px-3;
    }

    i {
      @apply inline-block text-xl text-gray-300;
    }
  }

  &__nav {
    @apply hidden;
    @apply lg:block;
    $nav: &;

    &-menu {
      @apply flex w-full font-light justify-center gap-x-5;

      > li {
        @apply cursor-pointer py-[30px] tracking-wider;
        @apply last:mr-0;

        .header__nav-menu-link {
          @apply py-[30px] font-medium;
        }

        &:hover {
          //&:after {
            //@apply absolute bottom-[-3px] z-10 block h-1 w-full bg-white content-[''];
          //}

          #{$nav}-child-menu {
            @apply visible top-[87px] h-full opacity-100;
          }
        }
      }
    }

    &-child-menu {
      @apply invisible absolute top-[110px] left-0 z-10 h-0 w-52 opacity-0 duration-500 ease-in-out;

      li {
        @apply bg-white text-left shadow-sm transition-all duration-300;
        @apply hover:cursor-pointer hover:bg-theme-dark-blue;
      }

      a {
        @apply block py-2 px-5;
        @apply hover:text-white;
      }
    }
  }

  &-mobile__nav {
    @apply absolute right-0 top-0 bottom-0 z-20 hidden w-3/4 overflow-y-auto bg-white p-5;

    &.-open {
      @apply block;

      + .back-drop {
        @apply block;
      }
    }

    &-child-menu {
      @apply hidden;

      &.-open {
        @apply block;
      }

      li {
        @apply px-5;
      }
    }

    &-menu-link {
      @apply inline-block p-4 text-lg;

      &.-open {
        @apply font-medium text-theme-gold-300;

        + .mobile-right-icon {
          @apply rotate-90;
        }
      }
    }

    .mobile-right-icon {
      @apply float-right p-5 text-xl;
    }
  }

  &-mobile-menu-icon {
    @apply py-1 text-4xl;
    @apply lg:hidden #{!important};
  }

  &-mobile-close-icon {
    @apply p-5 text-right text-3xl;
    @apply block #{!important};
  }

  .back-drop {
    @apply absolute inset-0 z-[11] hidden bg-slate-800 opacity-70;
  }
}

.mega-menu {
  @apply z-50 border-t-2 border-theme-light-blue bg-white shadow-lg;

  &__category {
    @apply relative;

    &:hover {
      .mega-menu__subcategories {
        @apply opacity-100 visible z-50;
      }
    }
  }

  &__icon {
    filter: brightness(0) saturate(100%) invert(19%) sepia(19%) saturate(1926%) hue-rotate(182deg) brightness(97%) contrast(85%);
    @apply transition-all duration-200;

    .group:hover & {
      filter: brightness(0) saturate(100%) invert(13%) sepia(32%) saturate(1137%) hue-rotate(182deg) brightness(97%) contrast(98%);
    }
  }

  &__link {
    @apply transition-all duration-200;

    &:hover {
      @apply bg-gray-50;

      span {
        @apply text-theme-dark-blue font-bold;
      }
    }
  }

  &__subcategories {
    @apply absolute left-0 top-full bg-white shadow-xl p-4 min-w-[200px] opacity-0 invisible transition-all duration-200;

    a {
      @apply transition-all duration-200;
    }
  }


}
