.page {
  &__wrapper {
    @apply container;
  }

  &__content {
    @apply py-8 px-8 min-h-[400px] bg-white -mt-6 relative shadow-md mb-8;

    h1, h2, h3, h4, h5, h6 {
      @apply text-3xl font-medium mb-5;
    }

    p {
      @apply leading-7 mb-4 #{!important};
    }

    &-left {
      @apply w-full mb-5;
      @apply lg:w-1/2;
    }

    &-right {
      @apply w-full;
      @apply lg:w-1/2 lg:pl-20;

      h3 {
        @apply mb-6 text-3xl;
      }

      p {
        @apply mb-5;
      }
    }

    &-slider {
      .splide__progress__bar {
        height: 2px;
        background: #eee;
        opacity: 0.3;
      }

      .splide__pagination__page {
        @apply opacity-50 bg-white shadow-lg;

        &.is-active {
          opacity: 1;
        }
      }
    }

    &-thumbnail-slider {
      @apply mt-3;

      .splide__slide {
        opacity: 0.3;
      }

      .splide__slide.is-active {
        opacity: 1;
      }
    }
  }

  &__top {
    @apply py-10 bg-gradient-to-b from-theme-light-blue to-theme-dark-blue md:from-white/90 md:to-th-body-color relative;

    &-bg {
      @apply absolute w-80 right-5 top-1/2 opacity-10 -translate-y-1/2;
    }
  }

  &__title {
    h1 {
      @apply text-4xl text-gray-100 md:text-6xl md:text-gray-600 mb-2;
      @apply lg:text-4xl;
    }
  }

  &__breadcrumb {
    @apply py-2;

    .wrapper {
      @apply px-1;
    }

    a, span {
      @apply font-light text-gray-400 transition-colors duration-200 ease-in text-sm;
    }

    a {
      @apply hover:text-gray-600;
    }
  }

  .our-working-zone {
    @apply flex items-start flex-col;
    @apply lg:flex-row;
  }

  .left-content {
    @apply mr-3 p-4 w-full;

    @screen lg {
      width: calc(100% - 300px);
    }

    p {
      @apply leading-loose;
    }

    ul {
      @apply list-inside
    }

    li {
      @apply list-disc leading-loose;
    }
  }

  .right-side-menu {
    @apply w-full p-8 shadow-md border-t-4 border-theme-dark-blue mt-5;
    @apply lg:w-72 lg:min-h-[250px] lg:mt-0;

    h3 {
      @apply font-medium mb-3 relative text-lg;

      &::before {
        @apply w-1 h-7 bg-theme-dark-blue absolute inline-block content-[''] -ml-2;
      }
    }

    &__item {
      @apply rounded-md;

      a {
        @apply py-2 px-3 mb-1 transition-colors duration-300 ease-in-out block rounded-md;
        @apply hover:bg-theme-dark-blue hover:text-white;
      }

      &.active {
        @apply bg-theme-dark-blue text-white;
      }
    }
  }

  .team {
    @apply flex flex-wrap;

    &-item {
      @apply mx-5 mt-5 mb-24 relative;

      width: calc(100% - 40px);

      @screen md {
        width: calc(50% - 40px);
      }

      @screen xl {
        width: calc(33.3% - 40px);
      }

      &__info {
        @apply absolute text-center p-5 -bottom-20 bg-white shadow-md left-10;

        width: calc(100% - 80px);

        &:hover {
          .desc {
            @apply opacity-100 visible max-h-24;
          }
        }
      }

      .name {
        @apply mb-2 text-theme-gold-300;
      }

      .mail {
        @apply mb-2;

        a {
          @apply text-sm font-light transition-all text-neutral-500;
          @apply hover:text-neutral-900;
        }
      }

      .desc {
        @apply mb-4 text-sm opacity-0 invisible max-h-0 duration-300 ease-out-expo transition-[max-height,opacity] text-slate-500 relative overflow-hidden;

        &::before {
          @apply content-[''] w-1/5 h-[1px] bg-theme-dark-blue block mx-auto my-4;
        }
      }

      .social {
        i {
          @apply text-neutral-300 text-lg transition-colors mx-1;
          @apply hover:text-theme-dark-blue;
        }
      }
    }

    &-detail {
      &__top {
        @apply flex mb-20;
      }

      &__bottom {
        @apply mt-32 mb-10 leading-loose;

        h2 {
          @apply first-letter:text-4xl font-bold;
        }
      }

      &__image {

      }

      &__content {
        @apply drop-shadow-xl -translate-x-14 translate-y-14 flex-1 p-20 bg-gradient-to-br to-white from-gray-200;

        h3 {
          @apply text-2xl mb-5 text-theme-dark-blue;
        }

        p {
          @apply mb-5 text-lg;
        }

        i {
          @apply text-2xl mx-1;
        }
      }
    }
  }

  .katalog-wrapper {
    @apply relative inline-block;

    span {
      @apply text-5xl font-bold absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-theme-dark-blue/70 text-white px-4 py-2 rounded-md w-full h-full text-center m-auto flex items-center justify-center pointer-events-none transition-all duration-300 ease-in-out;
    }

    &:hover {
      span {
        @apply bg-theme-dark-blue/90;
      }
    }
  }

  .katalog-kapak {
    @apply relative inline-block;
  }
}

.divider {
  @apply my-2 w-20 h-1 bg-theme-gold-300 block;
}

.carousel__button svg, .fancybox__button--close svg {
  stroke: white !important;
}

.fancybox__caption,
.fancybox__counter,
.fancybox__counter span {
  color: white !important;
}

.galery {
  @apply flex flex-wrap justify-center gap-6;

  &-item {
    @apply w-[220px] h-[200px] object-cover object-center;
  }
}

