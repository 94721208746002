@font-face {
  font-family: 'Quicksand';
  src: url('../fonts/Quicksand/Quicksand-Bold.eot');
  src: local('Quicksand Bold'), local('Quicksand-Bold'),
      url('../fonts/Quicksand/Quicksand-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Quicksand/Quicksand-Bold.woff2') format('woff2'),
      url('../fonts/Quicksand/Quicksand-Bold.woff') format('woff'),
      url('../fonts/Quicksand/Quicksand-Bold.ttf') format('truetype'),
      url('../fonts/Quicksand/Quicksand-Bold.svg#Quicksand-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Quicksand';
  src: url('../fonts/Quicksand/Quicksand-SemiBold.eot');
  src: local('Quicksand SemiBold'), local('Quicksand-SemiBold'),
      url('../fonts/Quicksand/Quicksand-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Quicksand/Quicksand-SemiBold.woff2') format('woff2'),
      url('../fonts/Quicksand/Quicksand-SemiBold.woff') format('woff'),
      url('../fonts/Quicksand/Quicksand-SemiBold.ttf') format('truetype'),
      url('../fonts/Quicksand/Quicksand-SemiBold.svg#Quicksand-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Quicksand';
  src: url('../fonts/Quicksand/Quicksand-Medium.eot');
  src: local('Quicksand Medium'), local('Quicksand-Medium'),
      url('../fonts/Quicksand/Quicksand-Medium.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Quicksand/Quicksand-Medium.woff2') format('woff2'),
      url('../fonts/Quicksand/Quicksand-Medium.woff') format('woff'),
      url('../fonts/Quicksand/Quicksand-Medium.ttf') format('truetype'),
      url('../fonts/Quicksand/Quicksand-Medium.svg#Quicksand-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Quicksand';
  src: url('../fonts/Quicksand/Quicksand-Light.eot');
  src: local('Quicksand Light'), local('Quicksand-Light'),
      url('../fonts/Quicksand/Quicksand-Light.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Quicksand/Quicksand-Light.woff2') format('woff2'),
      url('../fonts/Quicksand/Quicksand-Light.woff') format('woff'),
      url('../fonts/Quicksand/Quicksand-Light.ttf') format('truetype'),
      url('../fonts/Quicksand/Quicksand-Light.svg#Quicksand-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Quicksand';
  src: url('../fonts/Quicksand/Quicksand-Regular.eot');
  src: local('Quicksand Regular'), local('Quicksand-Regular'),
      url('../fonts/Quicksand/Quicksand-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Quicksand/Quicksand-Regular.woff2') format('woff2'),
      url('../fonts/Quicksand/Quicksand-Regular.woff') format('woff'),
      url('../fonts/Quicksand/Quicksand-Regular.ttf') format('truetype'),
      url('../fonts/Quicksand/Quicksand-Regular.svg#Quicksand-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

