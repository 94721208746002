@tailwind base;
@tailwind components;
@import 'components';

@tailwind utilities;
@import 'fonts';
@import 'index';
@import 'header';
@import 'footer';
@import '@fancyapps/ui/dist/fancybox.css';
@import 'page';
@import '@splidejs/splide/dist/css/splide.min';
@import 'slider';
@import 'contact';
@import 'category';
@import 'product';

body {
  @apply bg-th-body-color overflow-x-hidden;
}

html {
  scroll-behavior: smooth;
}
