.slider {
  @apply bg-[url('/images/header-bg.jpg')] h-[30vh] bg-no-repeat flex items-center justify-center flex-col bg-cover bg-center;

  @screen lg {
    height: calc(100vh - 120px);
  }

  &-title {
    @apply text-5xl text-center text-theme-dark-blue font-medium mb-8 w-3/4 mx-auto;
  }

  &-desc {
    @apply text-2xl text-center text-white w-4/5 mx-auto;
  }

  .splide__pagination {
    &__page {
      @apply w-20 rounded-none h-[6px];

      &.is-active {
        @apply scale-100 h-2;
      }
    }
  }
}

.hero-slider {
  @apply relative;

  .splide__slide {
    @apply relative;

    img {
      @apply h-[300px] md:h-[500px] w-full object-cover;
    }

    .text-center {
      @apply bg-black bg-opacity-50 p-8 rounded-lg;
    }
  }

  .splide__arrow {
    @apply bg-theme-light-blue hover:bg-theme-dark-blue transition-colors duration-200 w-10 h-10 opacity-70 hover:opacity-100;

    svg {
      @apply fill-white w-4 h-4;
    }

    &--prev {
      @apply left-4;
    }

    &--next {
      @apply right-4;
    }
  }

  .splide__pagination {
    @apply -bottom-4;

    .splide__pagination__page {
      @apply w-6 h-2 rounded-none bg-gray-300 rounded-md;

      &.is-active {
        @apply bg-theme-light-blue transform-none w-10;
      }
    }
  }
}

.main-slider {
  @apply relative;

  &__arrows {
    .splide__arrow {
      @apply bg-theme-light-blue hover:bg-theme-dark-blue transition-colors duration-200 w-10 h-10;

      svg {
        @apply fill-white w-4 h-4;
      }

      &--prev {
        @apply left-0 md:-left-12;
      }

      &--next {
        @apply right-0 md:-right-12;
      }
    }
  }
}

.thumbnail-slider {
  @apply mt-4;

  .splide__slide {
    @apply opacity-50 transition-opacity duration-200 cursor-pointer;

    &.is-active {
      @apply opacity-100 border-2 border-theme-light-blue;
    }

    &:hover {
      @apply opacity-80;
    }
  }
}

.recommendation-slider {
  @apply relative;

  &__arrows {
    .splide__arrow {
      @apply bg-theme-light-blue hover:bg-theme-dark-blue transition-colors duration-200 w-10 h-10;

      svg {
        @apply fill-white w-4 h-4;
      }

      &--prev {
        @apply left-0 md:-left-5;
      }

      &--next {
        @apply right-0 md:-right-5;
      }
    }
  }

  .splide__pagination {
    @apply -bottom-4;

    .splide__pagination__page {
      @apply w-6 h-2 rounded-none bg-gray-300 rounded-md;

      &.is-active {
        @apply bg-theme-light-blue transform-none w-10;
      }
    }
  }

  .splide__track {
    @apply py-4;
  }
}

.variant-slider {
  @apply relative;

  &__arrows {
    .splide__arrow {
      @apply bg-theme-light-blue hover:bg-theme-dark-blue transition-colors duration-200 w-10 h-10;

      svg {
        @apply fill-white w-4 h-4;
      }

      &--prev {
        @apply left-0 md:-left-5;
      }

      &--next {
        @apply right-0 md:-right-5;
      }
    }
  }

  .splide__pagination {
    @apply -bottom-4;

    .splide__pagination__page {
      @apply w-6 h-2 rounded-none bg-gray-300 rounded-md;

      &.is-active {
        @apply bg-theme-light-blue transform-none w-10;
      }
    }
  }

  .splide__track {
    @apply py-4;
  }
}

.variant-slider.no-slider {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;

  .variant-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    img {
      max-width: 100%;
      height: auto;
    }

    .urun_baslik {
      margin-top: 5px;
      font-size: 1rem;
      color: #333;
    }
  }
}
